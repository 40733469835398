import React from 'react'
import { graphql, Link } from 'gatsby'
import Select from 'react-select'
import Hero from '../components/hero'
import Layout from '../components/layout/layout'
import Search from '../components/ui-kit/search'
import ProfileCard from '../components/ui-kit/profileCard'
import SEO from '../components/layout/seo'
import { EmptyState, FilterWrapper, FilterContainer, FilterNav, GridContainer, HeroBody, HeroContainer, HeroHeading, HeroPanel, HeroWrapper, MainContainer, HighlightsContainer, PickedContainer, PickedItemContainer, ViewProfileLink, PickedMetaContainer, PickedTitle, PickedItemWrapper, FeaturedTitleTop, HighlightsWrapper, Arrow, CTAButton } from '../components/directory/styled'
import FeaturedProfileCard from '../components/ui-kit/featuredProfileCard'
import { useDirectory } from '../hooks/filters'
import { customSelectStyle } from '../styles/selectStyle'
import { capitalise, calculateDaysBetween } from '../utils/utils'

const DirectoryPage = ({ data: {
  contentfulPageDirectory: { hero, seoTitle, seoDescription, openGraphImage },
  allContentfulProfile,
  directoryMeta, featuredContent, pickedContent
}, location }) => {
  const { filters, profiles } = useDirectory(allContentfulProfile, directoryMeta)
  const heroHeading = hero.heading;
  const heroSubheading = hero.subheading; 
  const heroCTADestination = hero.ctaDestination;
  const heroCTAText = hero.ctaText;
  const [featured] = featuredContent.edges;

  return (
    <Layout location={location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
        // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}      
      />


      <HeroWrapper id="expert">
        <HeroContainer flex={true} width={12}>
          <HeroPanel>
            <HeroHeading>{heroHeading}</HeroHeading>
            <HeroBody>{heroSubheading}</HeroBody>
            <CTAButton
                    href={heroCTADestination}
                    target="__blank"
                    rel="nofollow"
                  >
                    {heroCTAText}
                    <Arrow />
              </CTAButton>
            <Search />
          </HeroPanel>

          
        </HeroContainer>
      </HeroWrapper>
     
      <HighlightsContainer>
      <FeaturedTitleTop>Featured Expert</FeaturedTitleTop>
        <HighlightsWrapper>

          <FeaturedProfileCard profile={featured.node} />
      

        <PickedContainer>
          <PickedTitle>More picks</PickedTitle>
          <PickedItemWrapper>
          {pickedContent?.edges?.slice(0,3).map((item) => {
            const daysBetween = calculateDaysBetween(
              new Date(),
              new Date(item.node.createdAt)
            )

            return (
         
              <PickedItemContainer key={item.node.name}>
                <div>
                  <Link to={item.node.slug}>
                    <h3>{item.node.name}</h3>
                  </Link>
                    <PickedMetaContainer>
                     <p>{item.node.bioExcerpt}</p>
                   
                   </PickedMetaContainer>
                  </div>

                  <ViewProfileLink><Link to={item.node.slug}>View profile</Link></ViewProfileLink>
                </PickedItemContainer>
         
            )
          })}
               </PickedItemWrapper>
        </PickedContainer>
        </HighlightsWrapper>
      </HighlightsContainer>


      <MainContainer>
      <FilterWrapper>
          <FilterContainer>
            <FilterNav>
              <Select
                onChange={filters.categoriesChangeCb}
                options={filters.categories}
                styles={customSelectStyle}
                value={filters.selectedCategory}
              />
              <Select
                onChange={filters.tagsChangeCb}
                options={filters.tags}
                styles={customSelectStyle}
                value={filters.selectedTag}
              />
              <Select
                onChange={filters.platformChangeCb}
                options={filters.platforms}
                styles={customSelectStyle}
                value={filters.selectedPlatform}
              />
            </FilterNav>
          </FilterContainer>
        </FilterWrapper>
        {profiles.length > 0 ? (
          <GridContainer width={12}>
            {profiles?.map((profile) => (
              <ProfileCard key={profile.node.slug} profile={profile} />
            ))}
          </GridContainer>
        ) : (
          <EmptyState>
            <h2>No results</h2>
          </EmptyState>
        )}
      </MainContainer>

      {/* <PaginationContainer flex={true} width={12}>
        <Pagination>
          {this.buildPaginationLinks(numberOfPages, humanPageNumber).map(
            (link) => link
          )}
        </Pagination>
          </PaginationContainer> */}
    </Layout>
  )
}

export default DirectoryPage

export const directoryQuery = graphql`
  query DirectoryQuery {
    contentfulPageDirectory {
      seoTitle
      seoDescription
      openGraphImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 85)
      }
      hero {
        heading
        subheading
        ctaText
        ctaDestination
      }
    }

    directoryMeta: allContentfulProfile {
      categories: distinct(field: category)
      expertisePrimary: distinct(field: primaryExpertise)
      expertiseSecondary: distinct(field: secondaryExpertise)
      location: distinct(field: locationData___locationText)
      platforms: distinct(field: platforms)
    }

    allContentfulProfile(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          createdAt
          name
          slug
          logo {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 55)
          }
          websiteUrl
          website
          foundingMember
          category
          primaryExpertise
          primaryImpact
          secondaryExpertise
          secondaryImpact
          teamSize
          platforms
          locationData {
            locationText
          }
          bioExcerpt
        }
      }
    }
    featuredContent: allContentfulProfile(
      sort: { fields: [createdAt], order: DESC }
      filter: { isFeatured: { eq: true } }
    ) {
      edges {
        node {
          createdAt
          name
          slug
          logo {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          websiteUrl
          website
          foundingMember
          category
          primaryExpertise
          primaryImpact
          secondaryExpertise
          secondaryImpact
          teamSize
          platforms
          locationData {
            locationText
          }
          bioExcerpt
        }
      }
    }
    pickedContent: allContentfulProfile(
      sort: { fields: [createdAt], order: DESC }
      filter: { isPicked: { eq: true } }
    ) {
      edges {
        node {
          createdAt
          name
          slug
          websiteUrl
          website
          foundingMember
          bioExcerpt
        }
      }
    }
  }
`
